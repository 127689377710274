import React, { Fragment, useState } from 'react'
import {
  Container,
  DownloadButton,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { PageLayout } from '../../../components/site/layout/page-layout'
import { OfferContent } from '../../../components/site/offer'
import classNames from 'classnames'
import { Tab } from '@headlessui/react'
import { PdfViewer } from "../../../components/core/pdf";

const offer = 'attestation'
const pdfAUrl = '/download/Approov-SafetyNet-Brief.pdf'
const pdfAName = 'Approov Mobile App Protection and Google SafetyNet.pdf'
const pdfIUrl = '/download/Approov-DeviceCheck-Brief.pdf'
const pdfIName = 'Approov Mobile App Protection and Apple DeviceCheck.pdf'

const ViewPage = ({ location }) => {
  const Headline = () => {
    return (
      <Section>
        <Container className="mb-8 container-lg">
          <h1 className="mt-12 mb-4 text-3xl text-center">
            Approov Mobile App Attestation Beyond DeviceCheck and SafetyNet
          </h1>
          <h3 className="mt-4 mb-2 text-2xl text-center text-gray-700 lg:mt-0">
            Complete App and Device Integrity without Backend Integration
            Headaches
          </h3>
        </Container>
      </Section>
    )
  }

  const PdfTab = ({ name, start, end }) => {
    return (
      <Tab as={Fragment}>
        {({ selected }) => (
          <button
            className={classNames(
              selected ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
              start ? 'rounded-tl-lg' : null,
              end ? 'rounded-tr-lg' : null,
              'group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
            )}
          >
            <span>{name}</span>
            <span
              className={classNames(
                selected ? 'bg-primary-500' : 'bg-transparent',
                'absolute inset-x-0 bottom-0 h-0.5'
              )}
            />
          </button>
        )}
      </Tab>
    )
  }

  const PdfTabs = () => {
    // define tab controls
    const [activeTab, selectTab] = useState(0)

    // track tab change in case it is changed programmatically
    const handleTabChange = (index) => {
      selectTab(index)
    }

    return (
      <Tab.Group
        key={activeTab} // hack to allow programmatic tab selection
        defaultIndex={activeTab}
        onChange={handleTabChange}
      >
        <Tab.List>
          <PdfTab name="Approov and Google SafetyNet" end />
          <PdfTab name="Approov and Apple DeviceCheck" start />
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="flex items-center justify-center py-4">
              <DownloadButton
                className="button-secondary button-lg"
                downloadUrl={pdfAUrl}
                fileName={pdfAName}
              >
                Download PDF
              </DownloadButton>
            </div>
            <PdfViewer file={pdfAUrl} />
          </Tab.Panel>
          <Tab.Panel>
            <div className="flex items-center justify-center py-4">
              <DownloadButton
                className="button-secondary button-lg"
                downloadUrl={pdfIUrl}
                fileName={pdfIName}
              >
                Download PDF
              </DownloadButton>
            </div>
            <PdfViewer file={pdfIUrl} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    )
  }

  const Action = () => {
    return (
      <>
        <p>
          Approov can help your business secure its mobile APIs quickly and
          effectively. Want to learn more about how we helped dozens of
          companies ensure that only their mobile apps can use their APIs? Talk
          to us.
        </p>

        <div className="flex flex-col items-center">
          <LinkButton
            className="button-secondary button-lg"
            href="/product/consult"
          >
            Talk to an Expert
          </LinkButton>
        </div>
      </>
    )
  }

  return (
    <PageLayout pathname={location.pathname}>

      <Headline />

      <OfferContent offer={offer}>
        <Section>
          <Container className="mb-8">
            <PdfTabs />
            <Action />
          </Container>
        </Section>
      </OfferContent>
    </PageLayout>
  )
}

export default ViewPage
